@mixin breakpoint($k, $v) {
  @if $k == "" {
    @content;
  }
  @else {
    @media (min-width: $v) {
      @content;
    }
  }
}

/* classes that breakpoints are necessary for */
$screens: ("", 0), (sm\:, 640px);
@each $k, $v in $screens {
  @include breakpoint($k, $v) {
    .#{$k}flex { display: flex; }
    
    .#{$k}flex-row { flex-direction: row; }
    
    .#{$k}flex-col { flex-direction: column; }

    .#{$k}flex-col-reverse { flex-direction: column-reverse; }
    
    .#{$k}justify-between { justify-content: space-between; }
    
    .#{$k}items-center { align-items: center; }
    
    .#{$k}items-start { align-items: flex-start; }
    
    .#{$k}items-end { align-items: flex-end; }
    
    .#{$k}items-baseline { align-items: baseline; }

    .#{$k}text-center { text-align: center; }

    .#{$k}text-left { text-align: left; }
  }
}

/* layout */
.container {
  max-width: var(--max-w);
}

.hidden {
  display: none;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

/* spacing */
$u: 0.25;
@each $i, $class in (p, padding), (m, margin) {
  @each $j, $size in (t, top), (r, right), (b, bottom), (l, left) {
    @each $k in 0,1,2,3,4,5,6,7,8 {
      .#{$i}#{$j}-#{$k} {
        #{$class}-#{$size}: #{$u * $k}rem;
      }
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* typography */
$weights: (thin, 100), (extralight, 200), (light, 300), (normal, 400), 
          (medium, 500), (semibold, 600), (bold, 700), (extrabold, 800);
@each $k, $v in $weights {
  .font-#{$k} {
    font-weight: #{$v};
  }
}

$texts: (xs, 0.75), (sm, 0.875), (base, 1), (lg, 1.125), (xl, 1.25),
        (2xl, 1.5), (3xl, 1.875), (4xl, 2.25), (5xl, 3), (6xl, 3.75);
@each $k, $s in $texts {
  .text-#{$k} {
    font-size: #{$s}rem;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.list-disc {
  list-style-type: disc;
  padding-inline-start: 22px;
}

/* interactivity */
.cursor-pointer {
  cursor: pointer;
}