@import "./pre.scss";
@import "./theme.scss";
@import "./atom.scss";
@import "./md.scss";
@import "./syntax.scss";
/* HUGO_IMPORT_START ./photoswipe.css HUGO_IMPORT_END */
@import "./custom.scss";


:root {
  --max-w: 780px;
  --font: 'LXGW WenKai Screen','LXGW WenKai', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

html {
  // font-size: 18px;
  // -webkit-text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  border-collapse: collapse;
}

body {
  
  margin: 0 16px;
  font-family: var(--font);
  font-weight: 400;
  line-height: 1.6;
  background-image: var(--back-image);
  background-color: var(--back);
  color: var(--text);
  transition-property: background-color, border-color, color;
  transition-duration: 0.5s;
}

main {
  animation: showup 0.7s;
}

main p a {
  color: var(--prime);
}

main p a:hover {
  text-decoration: underline;
}

main .search > input {
  width: 100%;
  padding: .5em;
  font-size: large;
  border: 2px solid var(--grid);
  border-radius: 2px;
  background-color: transparent;
  outline: none;
}

.cover {
  border-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  max-width: 100%;
}

.filter-img {
  filter: grayscale(100%);
  transition: filter 0.3s ease; /* 添加过渡效果 */
}

.filter-img:hover {
  filter: grayscale(0%); /* 鼠标悬停时恢复彩色 */
}


@keyframes showup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@media (min-width: 640px) {
  body {
    margin-top: calc(1rem + 2vh);
  }
}


// 全局滚动条美化
::-webkit-scrollbar {
  width: 8px; /* 垂直滚动条的宽度 */
  height: 6px; /* 水平滚动条的高度 */
}

/* 设置滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background: #e7c18f79; 
}

/* 设置滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #d1874263; 
}

/* 设置滚动条滑块的hover样式 */
::-webkit-scrollbar-thumb:hover {
  background: #d17d2e8e; 
}

