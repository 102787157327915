$heading: (h1, 2.25, 800), (h2, 2, 700), (h3, 1.75, 600), (h4, 1.5, 600),
           (h5, 1.25, 500), (h6, 1, 400);
@each $tag, $size, $weight in $heading {
  .md #{$tag} {
    font-size: #{$size}rem;
    font-weight: $weight;
    margin-top: 1rem;
    margin-bottom: .75rem;
  }
}

.md p {
  margin: .75rem 0;
}

.md pre {
  font-size: 14px;
  line-height: 1.3;
}

.md blockquote {
  background-color: #94949514;
  padding: 1px .85em;
  border-left: 4px solid var(--prime);
  font-style: italic;
}

.md table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 1em 0;
  overflow: auto;
}

.md table thead {
  border-top: 2px solid var(--text);
  border-bottom: 1px solid var(--text);
}

.md table tbody {
  border-bottom: 2px solid var(--text);
}

.md table th,
.md table td {
  padding: .25rem 1rem;
  border-bottom: 1px solid var(--text);

}

.md img {
  border-radius: var(--imgradius);
  box-shadow: var(--imgshadow);
  max-width: 100%;
}

.md .katex {
  overflow: auto hidden;
}

.md ul {
  list-style: disc;
  padding-inline-start: 30px;
}

.md ol {
  list-style: decimal;
  padding-inline-start: 30px;
}

.md ul input[type="checkbox"] {
  margin: 0;
  margin-right: 5px;
}

.md ul:has(input) {
  list-style: none;
  padding-inline-start: 8px;
}

.md li {
  margin-bottom: .5rem;
  line-height: 1.5;
}

.md ol,
.md ul,
.md blockquote,
.md .highlight {
  margin: 1rem 0;
}

.md blockquote p {
  margin: .45rem 0;
  line-height: 1.5;
  font-size: .95rem;
}

.md hr {
  margin: 1.5rem 0;
  border-color: #d3d3d317;
}

.md .footnotes {
  word-break: break-all;
  font-size: .9rem;
}

.md p sup {
  margin-left: 4px;
}

.md a {
  color: var(--prime);
}

.md a:hover {
  text-decoration: underline;
}