//为代码块顶部添加macos样式
.highlight:before {
    content: "";
    display: block;
    background: url(/img/code-header.svg);
    height: 43px;
    width: 100%;
    background-size: 57px;
    background-repeat: no-repeat;
    background-color: var(--chroma-bg);
    background-position: 20px 18px;
    
    border-top-left-radius: var(--mainradius);
    border-top-right-radius: var(--mainradius);
}

.highlight {
    box-shadow: var(--shadow-l1);
    border-bottom-left-radius: var(--mainradius); 
    border-bottom-right-radius: var(--mainradius);
    margin: 0.5rem;
}


// /* Background */ .bg { color:#e0def4;background-color:var(--chroma-bg); }
/* PreWrapper */ .chroma { color:#e0def4;background-color:var(--chroma-bg); border-bottom-left-radius: var(--mainradius); border-bottom-right-radius: var(--mainradius); padding: 0.5rem; overflow: auto;}

/* Error */ .chroma .err { color:#eb6f92 }

/* LineLink */ .chroma .lnlinks { outline:none;text-decoration:none;color:inherit }
/* LineTableTD */ .chroma .lntd { vertical-align:top;padding:0;margin:0;border:0; }
/* LineTable */ .chroma .lntable { border-spacing:0;padding:0;margin:0;border:0; tbody {border-bottom: none;} border-bottom-left-radius: var(--mainradius); border-bottom-right-radius: var(--mainradius);}
/* LineHighlight */ .chroma .hl { background-color:#39374a }
/* LineNumbersTable */ .chroma .lnt { white-space:pre;-webkit-user-select:none;user-select:none;margin-right:0.4em;padding:0 0.4em 0 0.4em;color:#7f7f7f }
/* LineNumbers */ .chroma .ln { white-space:pre;-webkit-user-select:none;user-select:none;margin-right:0.4em;padding:0 0.4em 0 0.4em;color:#7f7f7f }
/* Line */ .chroma .line { display:flex; }
/* Keyword */ .chroma .k { color:#3e8fb0 }
/* KeywordConstant */ .chroma .kc { color:#3e8fb0 }
/* KeywordDeclaration */ .chroma .kd { color:#3e8fb0 }
/* KeywordNamespace */ .chroma .kn { color:#c4a7e7 }
/* KeywordPseudo */ .chroma .kp { color:#3e8fb0 }
/* KeywordReserved */ .chroma .kr { color:#3e8fb0 }
/* KeywordType */ .chroma .kt { color:#3e8fb0 }
/* Name */ .chroma .n { color:#ea9a97 }
/* NameAttribute */ .chroma .na { color:#ea9a97 }
/* NameBuiltin */ .chroma .nb { color:#ea9a97 }
/* NameBuiltinPseudo */ .chroma .bp { color:#ea9a97 }
/* NameClass */ .chroma .nc { color:#9ccfd8 }
/* NameConstant */ .chroma .no { color:#f6c177 }
/* NameDecorator */ .chroma .nd { color:#908caa }
/* NameEntity */ .chroma .ni { color:#ea9a97 }
/* NameException */ .chroma .ne { color:#3e8fb0 }
/* NameFunction */ .chroma .nf { color:#ea9a97 }
/* NameFunctionMagic */ .chroma .fm { color:#ea9a97 }
/* NameLabel */ .chroma .nl { color:#ea9a97 }
/* NameNamespace */ .chroma .nn { color:#ea9a97 }

/* NameProperty */ .chroma .py { color:#ea9a97 }
/* NameTag */ .chroma .nt { color:#ea9a97 }
/* NameVariable */ .chroma .nv { color:#ea9a97 }
/* NameVariableClass */ .chroma .vc { color:#ea9a97 }
/* NameVariableGlobal */ .chroma .vg { color:#ea9a97 }
/* NameVariableInstance */ .chroma .vi { color:#ea9a97 }
/* NameVariableMagic */ .chroma .vm { color:#ea9a97 }
/* Literal */ .chroma .l { color:#f6c177 }
/* LiteralDate */ .chroma .ld { color:#f6c177 }
/* LiteralString */ .chroma .s { color:#f6c177 }
/* LiteralStringAffix */ .chroma .sa { color:#f6c177 }
/* LiteralStringBacktick */ .chroma .sb { color:#f6c177 }
/* LiteralStringChar */ .chroma .sc { color:#f6c177 }
/* LiteralStringDelimiter */ .chroma .dl { color:#f6c177 }
/* LiteralStringDoc */ .chroma .sd { color:#f6c177 }
/* LiteralStringDouble */ .chroma .s2 { color:#f6c177 }
/* LiteralStringEscape */ .chroma .se { color:#3e8fb0 }
/* LiteralStringHeredoc */ .chroma .sh { color:#f6c177 }
/* LiteralStringInterpol */ .chroma .si { color:#f6c177 }
/* LiteralStringOther */ .chroma .sx { color:#f6c177 }
/* LiteralStringRegex */ .chroma .sr { color:#f6c177 }
/* LiteralStringSingle */ .chroma .s1 { color:#f6c177 }
/* LiteralStringSymbol */ .chroma .ss { color:#f6c177 }
/* LiteralNumber */ .chroma .m { color:#f6c177 }
/* LiteralNumberBin */ .chroma .mb { color:#f6c177 }
/* LiteralNumberFloat */ .chroma .mf { color:#f6c177 }
/* LiteralNumberHex */ .chroma .mh { color:#f6c177 }
/* LiteralNumberInteger */ .chroma .mi { color:#f6c177 }
/* LiteralNumberIntegerLong */ .chroma .il { color:#f6c177 }
/* LiteralNumberOct */ .chroma .mo { color:#f6c177 }
/* Operator */ .chroma .o { color:#908caa }
/* OperatorWord */ .chroma .ow { color:#908caa }
/* Punctuation */ .chroma .p { color:#908caa }
/* Comment */ .chroma .c { color:#6e6a86 }
/* CommentHashbang */ .chroma .ch { color:#6e6a86 }
/* CommentMultiline */ .chroma .cm { color:#6e6a86 }
/* CommentSingle */ .chroma .c1 { color:#6e6a86 }
/* CommentSpecial */ .chroma .cs { color:#6e6a86 }
/* CommentPreproc */ .chroma .cp { color:#6e6a86 }
/* CommentPreprocFile */ .chroma .cpf { color:#6e6a86 }

/* GenericDeleted */ .chroma .gd { color:#eb6f92 }
/* GenericEmph */ .chroma .ge { font-style:italic }

/* GenericInserted */ .chroma .gi { color:#9ccfd8 }

/* GenericStrong */ .chroma .gs { font-weight:bold }
/* GenericSubheading */ .chroma .gu { color:#c4a7e7 }
